<template>
  <div class="main_app_container">
    <router-view />
  </div>
</template>

<script>
  export default {
    mounted() {
      document.addEventListener(
        'touchstart',
        function (event) {
          if (event.touches.length > 1) {
            event.preventDefault()
            return
          }
        },
        { passive: false }
      )
    },
  }
</script>

<style lang="scss" scoped>
  .main_app_container {
    overflow: hidden;
    height: 100vh;
    width: 100vw;
    user-select: none;
    background-image: url('/images/main_theme.png');
    // background-image: url('/images/background_lobby_2.jpg');
    background-size: cover; /* Adjusted for better background fitting */
    background-position: center; /* Optional: Center the background image */
    background-repeat: no-repeat;
  }

  // @media screen and (max-aspect-ratio: 16/10) {
  //   .main_app_container {
  //     background-size: cover;
  //     background-position: 50%;
  //   }
  // }
</style>
